import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { useStore } from 'vuex'
export function useSocket () {
  const store = useStore()
  const initSocket = () => {
    window.Pusher = Pusher
    console.log('window.Echo New')
    if (window.Echo === undefined) {
      window.Echo = new Echo({
        // 这里是固定值 pusher
        broadcaster: 'pusher',
        // 这里要和你在 .env 中配置的 PUSHER_APP_KEY 保持一致
        key: 'creator',
        cluster: 'creator',
        wsHost: `${process.env.VUE_APP_WEB_SOCKET_URL}`,
        // 这里是我们在上一步启动 socket 监听的端口
        wsPort: '',
        wssPort: '',
        wsPath: '/websocket/v1',
        // 这个也要加上
        forceTLS: JSON.parse(process.env.VUE_APP_FORCE_TLS),
        disableStats: true,
        encrypted: false,
        enabledTransports: ['ws', 'wss'],
        devMode: true,
        auth: {
          headers: {
            Authorization: store.state.User.token
          }
        },
        authEndpoint: `${process.env.VUE_APP_AXIOS_BASEURL}/broadcasting/auth`
      })
      enterySocket()
      listSocket()
      onlineSocket()
    }
  }

  const enterySocket = () => {
    connectAllSocket(store.state.User.chatList)
    // 上線
    window.Echo.connector.pusher.send_event('online', {
      token: store.state.User.token
    })
  }

  const connectAllSocket = array => {
    array.forEach(e => {
      // 檢查有沒有監聽過頻道
      if (
        window.Echo.connector.channels[`private-chat.${e.chat_id}`] ===
        undefined
      ) {
        window.Echo.private(`chat.${e.chat_id}`, { isPrivate: true })
          .listen('.ChatMsgEvent', e => {
            // 最新訊息
            console.log(`chat.${e.data.chat_id} => 收到 最新訊息`)
            setTimeout(() => {
              // 檢查有沒有開啟對話視窗，若有重新取得detail
              reGetChatDetail(e.data.chat_id)
            }, 500)
          })
          .listen('.ChatRoomEvent', e => {
            // 會員訊息置頂
            console.log(`chat.${e.data.chat_id} => 會員訊息置頂`)
            console.log('重新刷新 chat list')
          })

        console.log('連線', `private-chat.${e.chat_id}`)
      }
    })
  }

  // 當打開的是當前 chat_id 就從新抓取detail
  const reGetChatDetail = socketChatId => {
    const currentUserChatId = window.$chatIndex.currentUser.chat_id
    if (currentUserChatId !== socketChatId) {
      // 沒有打開視窗，獲取chatList
      console.log(`reGetChatDetail => 取得聊天列表`)
      store.dispatch('User/fetcChatListAction')
      return false
    }
    console.log(`reGetChatDetail => 已打開聊天視窗，取得聊天詳細`)
    window.$chatIndex.openUser(window.$chatIndex.currentUser)
  }

  const listSocket = () => {
    window.Echo.private(`chatList.` + store.state.User.user.account, {
      isPrivate: true
    }).listen('.ChatRoomEvent', e => {
      console.log(`ChatRoomEvent.${e.data} => 取得聊天列表`)
      console.log(e)
      if (window.$chatIndex.currentUser.chat_id) {
        // console.log(`ChatRoomEvent.${e.data} => 已打開對話視窗，刷新detail`)
        window.$chatIndex.openUser(window.$chatIndex.currentUser)
      }
      store.dispatch('User/updateChatListInObjectAction', e.data.list.data)
    })
  }

  const onlineSocket = () => {
    setInterval(function () {
      window.Echo.connector.pusher.send_event('get_online', {
        token: store.state.User.token
      })
    }, 5000)
  }

  return {
    reGetChatDetail,
    initSocket,
    enterySocket,
    connectAllSocket
  }
}
export default useSocket